import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion'; // Import motion from framer-motion
import { Card, Grid, Container, Paper } from '@mui/material';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';

const DemandLetter = () => {
    const [loading, setLoading] = useState(false);
    const [collected, setCollected] = useState([]);

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        fetch('https://turki-bd-server-ten.vercel.app/getDemand')
            .then(res => res.json())
            .then(data => {
                setCollected(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setLoading(false);
            });
    }, []);

    return (
        <section className="bg-black">
            <Header/>
            <Container>
           
                <Grid sx={{marginTop:"30px"}} container spacing={4}>
                    {collected.map(item => (
                        <Grid item xs={12} sm={6} md={4} key={item.id}>
                            <AnimatePresence>
                                <motion.div
                                    initial={{ x: '100vw' }} // Initial position outside the viewport
                                    animate={{ x: 0 }} // Final position at 0 (left side)
                                    exit={{ x: '-70vw' }} // Exit position outside the viewport from left side
                                    transition={{ type: 'spring', stiffness: 25, duration: 60 }} // Spring animation with specified stiffness and duration
                                    key="card" // Unique key for motion component
                                >
                                     <div className="col">
                                     <div className="card custom-cart h-100 shadow" style={{background:"#182533",color:"white",border:"1px solid #182533"}}>
                <img title="question" src={item.image1}
                    className="img-fluid rounded-start w-100 " style={{ height: "330px" }} allow="autoplay"></img>
                     <div className="card-body">
                   
                   <div className="d-flex justify-content-between pt-3" >
                       <button  className="btn-style download-btn " > <a style={{textDecoration:"none"}} href={item.image1} download>Download</a></button>
                       <button  className="btn-style download-btn " ><a style={{textDecoration:"none"}} href={item.image1} target="_blank" rel="noopener noreferrer">View</a></button>
                   </div>
               </div>
                    </div>
                
                                      </div>
                             
                              
                                   
                                  
                             
                                </motion.div>
                            </AnimatePresence>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer/>
        </section>
    )
}

export default DemandLetter;
