import React from 'react';
import AboutUs from '../AboutUs/AboutUs';
import SelectionProcess from '../SelectionProcess/SelectionProcess';
import Header from '../../Shared/Header/Header';
import Footer from '../../Shared/Footer/Footer';
import Team from '../Team/Team';

const Aboutsdata = () => {
    return (
        <>
        <section className='bg-white'>
            <Header></Header>
        <div>
            <AboutUs/>
          
            
        </div>
        <Footer></Footer>
        </section>
        </>
    );
};

export default Aboutsdata;