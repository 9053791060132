import React, { useContext, useEffect, useState } from 'react';
// import QuestionCart from './QuestionCart';
import { NavLink } from "react-router-dom";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// import './AllCategoriesSharee.css'


import {
    Box,
    Button,
    CardMedia,
    Container,
    Grid,
    Pagination,
    Paper,
    Rating,
    Stack,
    Typography,
  } from "@mui/material";


import ReactPaginate from 'react-paginate';
import { CircularProgress} from '@mui/material';
import Swal from 'sweetalert2';
import Header from '../../Shared/Header/Header';
import Footer from '../../Shared/Footer/Footer';
const BlogMedia = () => {

    

   

   

    const [questions, setQuestions] = useState([]);
    const [model, setModel] = useState([]);
   
    const [categories,setCategories]=useState("");
    const [sizing,setsizing]=useState("");
    const [warrenty,setwarrenty]=useState("");
    const [material,setmaterial]=useState("");
    const [searchValue,setSearchValue]= useState('')
   
  
    const [page, setPage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const size = 10;

    

    const handlePageChange = (data) => {
        setPage(data.selected);
    }
    const fetchData = () => {
       
        fetch(`https://turki-bd-server-ten.vercel.app/getDemands?categories=${categories}&&sizing=${sizing}&&warrenty=${warrenty}&&material=${material}&&size=${size}`)
        .then(res => res.json())
        .then(data => {
            setQuestions(data.alldata)
            setModel(data.alldata)
            const count = data.count;
            const pageNumber = Math.ceil(count / size)
            setPageCount(pageNumber)
        })
      }
      useEffect(() => {
        fetchData()
      }, [categories, page,size,sizing,warrenty,material,size])

    
    
    
  
   
   
   



    useEffect(()=>{
        fetch('https://turki-bd-server-ten.vercel.app/getDemands')
        .then(res=>res.json())
        .then(data=>setModel(data.alldata))
    },[])
      
  

    

   
  const loading =
  <Box sx={{ textAlign: 'center', padding: '100px 0' }}>
      <CircularProgress color="secondary" />
      <Typography>Loading...</Typography>
  </Box>

   // alert 
   const alert = (icon, title, text) => {
    Swal.fire({
        position: 'center',
        icon: icon,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1500
    })
}
    
 
  

       const placeholder = 'Search by Sharee Product Name';

    return (
        
    <div>
        {/* <Header></Header> */}
          <div className='bg-white'>
            <Header></Header>
          <div className="container text-black mt-5 mb-5">
            <div className="row ">
              
                
            </div>
            {/* {questions.length ? */}
            <div className="row g-4" >
                <div className="col-12 col-md-2">
                    <div className="question-sidebar">
                        
                        <form
                            onChange={(e) => setCategories(e.target.value)}
                        >

                             
                         {/* {
                          model.map((models)=>( */}
                            {/* <div> */}
                           <div className='brands mt-3'>
                            <h5 style={{fontWeight:"700",fontSize:"26px"}} className='text-black texts-design main-parts'>Country Letter</h5>
                           <div className="form-check align-items-center me-3">
                                <input className="form-check-input mt-2" type="checkbox" value="saudi" id="flexCheckDefault" style={{fontSize:"20px"}} />
                                <label className="form-check-label fw-bold all text-black me-4 " for="flexCheckDefault" style={{fontSize:"26px"}}>
                                SaudiArabia
                                </label>
                            </div>
                            <div className="form-check align-items-center me-3">
                                <input className="form-check-input mt-2" type="checkbox" value="united" id="flexCheckDefault" style={{fontSize:"20px"}}/>
                                <label className="form-check-label fw-bold all me-4 text-black " for="flexCheckDefault" style={{fontSize:"26px"}}>
                                UnitedArab
                                </label>
                            </div>
                            <div className="form-check align-items-center me-3">
                                <input className="form-check-input mt-2" type="checkbox" value="singapore" id="flexCheckDefault" style={{fontSize:"20px"}}/>
                                <label className="form-check-label fw-bold all me-4 text-black " for="flexCheckDefault" style={{fontSize:"26px"}}>
                                SinGapore
                                </label>
                            </div>
                            <div className="form-check align-items-center me-3">
                                <input className="form-check-input mt-2" type="checkbox" value="namibia" id="flexCheckDefault" style={{fontSize:"20px"}} />
                                <label className="form-check-label fw-bold all me-5 text-black " for="flexCheckDefault" style={{fontSize:"26px"}}>
                                Namibia
                                </label>
                            </div>
                            <div className="form-check align-items-center me-3">
                                <input className="form-check-input mt-2" type="checkbox" value="libya" id="flexCheckDefault" style={{fontSize:"20px"}}/>
                                <label className="form-check-label fw-bold all me-5 text-black " for="flexCheckDefault" style={{fontSize:"26px"}}>
                                LibyaCountry
                                </label>
                            </div>
                            <div className="form-check align-items-center me-3">
                                <input className="form-check-input mt-2" type="checkbox" value="malaysia" id="flexCheckDefault" style={{fontSize:"20px"}} />
                                <label className="form-check-label fw-bold all me-5 text-black " for="flexCheckDefault" style={{fontSize:"26px"}}>
                                Malaysia
                                </label>
                            </div>

                           </div>

                           </form>
                           
                        
                       
                            {/* </div> */}
                       

                       
                        
                    </div>
                </div>
                <div className="col-12 col-md-10">
                    
                      

                          
      <div className="">
         <Grid
          container
          spacing={2}
          sx={{ mt: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
         {
         
         questions.length === 0 ?loading
:
  
         
         
         model?.map((single) => (
            <Grid sx={{ py: 3 }} key={single._id} item xs={4} sm={4} md={4}>
              <Paper
                sx={{
                  p: 1,
                  margin: "auto",
                  maxWidth: 500,
                  flexGrow: 1,
                  boxShadow: "0px 14px 22px rgb(42 135 158 / 50%)"
                }}
              >
                <Grid  container spacing={2} columns={{ xs: 4, sm: 8, md: 4 }}>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className='photo'>
                    <div className='photoShops photoalbums'>
                      <img height="230" width="330" style={{borderRadius:"10px"}} src={single?.image1}></img>
                   
                    </div>
                   </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={8} pl={2} my={3}>
                  <div className="d-flex justify-content-between pt-3" >
                       <button  className="bg-black px-2 py-2" style={{borderRadius:"10px"}}> <a className=' text-white' style={{textDecoration:"none",fontWeight:"600",}} href={single.image1} target="_blank" download>Download</a></button>
                       <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href={single.image1} target="_blank" rel="noopener noreferrer">View</a></button>
                   </div>
                  </Grid>
                </Grid>
               
              </Paper>
            </Grid>
          ))}
        </Grid>
                            </div>
                    
                 </div>

                <div className="d-flex mt-5">
                    <div className='mx-auto'>

                    <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            pageCount={pageCount}
                            onPageChange={handlePageChange}
                            containerClassName='pagination'
                            pageClassName='page-item'
                            pageLinkClassName='page-link'
                            previousClassName='page-link'
                            nextClassName='page-link'
                            breakClassName='page-item'
                            breakLinkClassName='page-link'
                            activeClassName='active'
                        />

                    </div>
                </div>


            </div >

            {/* : <div><h5>Loading...</h5></div>} */}



        </div >
        <Footer></Footer>
      </div>
    </div>
    );
};

export default BlogMedia;