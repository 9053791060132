import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Shared/Header/Header";
import Footer from "../Shared/Footer/Footer";
import { Container } from "react-bootstrap";

const Recruitment = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://turki-bd-server-ten.vercel.app/getRecruitment`
        );
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const tittle = data[0]?.tittle;
  const description = data[0]?.description;
  const tittle2 = data[0]?.tittle2;
  const description2 = data[0]?.description2;
  const subtitle = data[0]?.subtitle;
  const categories1 = data[0]?.categories1;
  const categoriesDetails1 = data[0]?.categoriesDetails1;
  const categories2 = data[0]?.categories2;
  const categoriesDetails2 = data[0]?.categoriesDetails2;
  const categories3 = data[0]?.categories3;
  const categoriesDetails3 = data[0]?.categoriesDetails3;
  const categories4 = data[0]?.categories4;
  const categoriesDetails4 = data[0]?.categoriesDetails4;
  const subtitle2 = data[0]?.subtitle2;
  const categories5 = data[0]?.categories5;
  const categoriesDetails5 = data[0]?.categoriesDetails5;
  const categories6 = data[0]?.categories6;
  const categoriesDetails6 = data[0]?.categoriesDetails6;
  const categories7 = data[0]?.categories7;
  const categoriesDetails7 = data[0]?.categoriesDetails7;
  const categories8 = data[0]?.categories8;
  const categoriesDetails8 = data[0]?.categoriesDetails8;
  const categories9 = data[0]?.categories9;
  const categoriesDetails9 = data[0]?.categoriesDetails9;
  const categories10 = data[0]?.categories10;
  const categoriesDetails10 = data[0]?.categoriesDetails10;
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <section className="bg-white">
            <Header />
            <Container sx={{ my: 10 }}>
              <div className="text-black mt-5">
                <Typography sx={{ fontSize: 32, fontWeight: 600 }} gutterBottom>
                  {tittle}
                </Typography>

                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {description}
                </Typography>
              </div>

              <div className="text-black mt-5">
                <Typography
                  sx={{ fontSize: 32, textAlign: "left", fontWeight: 600 }}
                >
                  {tittle2}
                </Typography>

                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {description2}
                </Typography>
              </div>

              <div className="text-black mt-5">
                <Typography
                  sx={{ fontSize: 32, marginTop: "99px", fontWeight: 600 }}
                >
                  {subtitle}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  i. {categories1}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails1}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  ii. {categories2}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails2}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  iii. {categories3}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails3}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  iv. {categories4}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails4}
                </Typography>
              </div>

              <div className="text-black ">
                <Typography
                  sx={{ fontSize: 32, marginTop: "99px", fontWeight: 600 }}
                >
                  {subtitle2}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  i. {categories5}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails5}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  ii. {categories6}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails6}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  iii. {categories7}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails7}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  iv. {categories8}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails8}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  v. {categories9}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails9}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 27,
                    textAlign: "left",
                    marginTop: "20px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                  gutterBottom
                >
                  vi. {categories10}
                </Typography>
                <Typography
                  sx={{ fontSize: 20, textAlign: "left", fontWeight: 500 }}
                  variant="body2"
                  gutterBottom
                >
                  {categoriesDetails10}
                </Typography>
              </div>
            </Container>

            <Footer />
          </section>
        </>
      )}
    </>
  );
};

export default Recruitment;
