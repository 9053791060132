import React, { useEffect, useState } from 'react';
import Footer from '../../Shared/Footer/Footer';
import Header from '../../Shared/Header/Header';
import Banners from '../Banner/Banners';
import Bonus from '../Bonus/Bonus';
import Choose from '../Choose/Choose';
import Assoicate from '../Assoicate/Assoicate';
import ChooseTurki from '../ChooseTurki/ChooseTurki';
import Tour from '../../Tour/Tour';
import Recruitment from '../../Recruitments/Recruitment';
import SystemRecruitment from '../../SystemRecruitment/SystemRecruitment';
import HomeRecruitment from '../../HomeRecruitment/HomeRecruitment';




const Home = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState([]);
    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`https://turki-bd-server-ten.vercel.app/getAssociate`);
          const result = await response.json();
          setData(result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
      fetchData();
    }, []);

    return (
       <section>
        {loading ? (
        <></>
      ) : ( 
        <div style={{background:"white"}}>
            <Header></Header>
            <Banners></Banners>
            <Assoicate/>
            <ChooseTurki/>
            <Tour/>
            <HomeRecruitment/>
            {/* <SystemRecruitment/> */}
            {/* <Bonus></Bonus> */}
            
           
          
            {/* <div className='mt-5'>
            // <Choose></Choose>
            </div> */}
          
            
            {/* <Patner></Patner> */}
            
            <Footer></Footer>
            
        </div>
      )}
         
       </section>
    );
};

export default Home;