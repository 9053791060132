import { Box, Container, Grid, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";



// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
const TraningPart = () => {
    return (
        <div>
    
        <Container sx={{ my: 10 }}>
      <Grid container spacing={2}>
        <Grid
          // data-aos="fade-right"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="3000"
          sx={{textAlign:"left", color:"white"}}
          item xs={12} sm={12} md={6}>
             <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"20px",marginTop:"80px",textAlign:"justify"}} color="white" variant="h3">
             Bangladesh is a small and densely populated developing country. Among the totalpopulation more than 
50% remained below the poverty line. The Bangladesh is a land of agriculture but the agriculture cannot 
bear the burden of its huge population. The day laborers do not get the opportunity work to in the agricultural sector and more than half of the time of the year they have to remain idle. Again, from the beginning of the decade 1990 in the country it has been established the Garments and other Industries. Apart 
from that due rapid urbanization and industrial development the needs of construction tremendously 
increased. Both the sector requires a lot of skilled labor and workers. Unfortunately, in the country there 
exist huge unemployed and underemployed labors from both sex but having no skills and capacity they 
do not get chance to work in these rapid growing sectors. 
    </Typography>
        
        </Grid>
    
        <Grid
         
          sx={{textAlign:"left", color:"black"}}
          item xs={12} sm={12} md={6}>
      <Box sx={{marginTop:10}}>
  <>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    // centeredSlides={true}
                    slidesPerView={3}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        loop: true,
                        speed: 600,

                        slideShadows: true
                    }
                    }
                    autoplay={{
                        delay: 1000
                    }}


                    pagination={true}
                    modules={[EffectCoverflow, Pagination, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src="https://i.ibb.co/K56t9qX/ct.png" alt="..." width="450" height="350" />
                        <button  className="bg-black px-4 py-2 text-center" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/K56t9qX/ct.png" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/r3RZ0Ks/img007.jpg" alt="..." width="450" height="350" />
                        <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/r3RZ0Ks/img007.jpg" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src="https://i.ibb.co/K56t9qX/ct.png" alt="..." width="450" height="350" />
                        <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/r3RZ0Ks/img007.jpg" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/r3RZ0Ks/img007.jpg" alt="..." width="350" height="350" />
                        <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/r3RZ0Ks/img007.jpg" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    
                    

                </Swiper>
            </>
  </Box>

      
          </Grid>
    
      
        
      </Grid>
    
      
    </Container>
       
      </div>
    );
};

export default TraningPart;