import { Card, CardActions, CardContent, CardMedia, Grid, Paper, Typography,Container, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

// import './OurDoctor.css'
const ChooseTurki = () => {

     const [data, setData] = useState([]);
      const [loading, setLoading] = useState([]);
      useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`https://turki-bd-server-ten.vercel.app/getChoose`);
            const result = await response.json();
            setData(result);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        }
        fetchData();
      }, []);
     //  console.log(data)
   const tittle=data[0]?.tittle
    return (
        <div>

<Container>
{data?.length > 0 ? (
<Box>
<h2 style={{fontSize:"2.8em", fontWeight:"700",marginTop:"100px", color: "black"}}>{tittle}</h2>
        <hr className='' style={{  width: '15%', height: '5px', backgroundColor: 'black', display:"inline-block", border:0}} />
<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
{/* 1st doctr  */}

{data.map((data) => (
     <Grid item xs={2} sm={4} md={4}>
     <Paper sx={{py:2}} elevation={5}>
     <Card sx={{ minWidth: 275, border: 0, boxShadow: 0  }}>
    
     <Box  sx={{display:"flex"}}>
    <Box className='p-2 '>
    <Typography className="icons" sx={{fontWeight:700,fontSize:"45px"}} variant="h5">

<i class="far fa-clock mt-5"></i>
</Typography>
    </Box>
<Box sx={{textAlign:"left"}}>
<Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"25px"}} color="white" variant="p">
{data?.subtittle}
</Typography>
<p className="dones ms-3 p-2" style={{fontWeight:"400"}}  color="white" variant="p">
{data?.subDetails}
</p>
<button  style={{borderRadius:"10px"}} className='ms-3 bg-black text-white px-3 py-2'>Read More</button>
</Box>
    </Box>
<CardContent>





</CardContent>

</Card>
</Paper>
</Grid>
 ))}

    

</Grid>
</Box>

) : (
  <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '40vw', height: '50vh' }}>
  <Spinner animation="border" variant="primary" />
  <div className="bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
  <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px' }}></div>
  <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px', maxWidth: '330px' }}></div>
  <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px', maxWidth: '300px' }}></div>
  <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px', maxWidth: '360px' }}></div>
  {/* <Spinner animation="border" /> */}
  <span className="sr-only">Loading...</span>
</div>
    )}
</Container>

            
        </div>
    );
};

export default ChooseTurki;