import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardMedia, Container, Grid, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Header from '../../Shared/Header/Header';
import Footer from '../../Shared/Footer/Footer';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";

const AboutUs = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

      const [data, setData] = useState([]);
      const [loading, setLoading] = useState([]);
      useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`https://turki-bd-server-ten.vercel.app/getAbout`);
            const result = await response.json();
            setData(result);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        }
        fetchData();
      }, []);
      console.log(data)
   const tittle= data[0]?.tittle
   const description = data[0]?.description
   const desginaton = data[0]?.desginaton
   const image1 = data[0]?.image1
   const name = data[0]?.name

    return (
      <div>
      
    
        <Container sx={{ my: 10 }}>



        <div>
    
    <Container sx={{ my: 10 }}>
    <Paper sx={{py:2, padding:5}} elevation={5}>
     <Card sx={{ minWidth: 275, border: 0, boxShadow: 0  }}>
 <Grid container spacing={2}>
    <Grid
  
      sx={{textAlign:"left", color:"white"}}
      item xs={12} sm={12} md={6}>
     
     <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"20px",marginTop:"80px",textAlign:"justify"}} color="white" variant="h3" >
   The Turki Associate Ltd since its inception has the credit of mobilization of over 5,000 Manpower per 
year to different employers globally, directly and through its closely held associates from Bangladesh 
and outside. The Turki Associate Ltd success story is intimately linked up to the overall success story of 
Manpower mobilization from Bangladesh. In this arena, there is no denying to the fact that The Turki 
Associate Ltd. acts as a leader and had been playing the most significant role at home and aboard 
concerning the maximization of manpower mobilization from Bangladesh.
    </Typography>
     
      <br/>
     
   
    </Grid>

    <Grid
     
      sx={{textAlign:"left", color:"white"}}
      item xs={12} sm={12} md={6}>
        
   
   <Box sx={{marginLeft:10}}>
   <img className='mt-5' height="280px" width="350px" src="https://i.ibb.co/bXrb1gL/chairman.jpg" alt="" />
   <Typography
            sx={{ fontSize: 18,fontWeight:500,marginTop:4,color:"black"}}
            variant="body2"
            gutterBottom
          >
          
          Sahariea Sarmin
          </Typography>
          <Typography
            sx={{ fontSize: 18,fontWeight:500,color:"black"}}
            variant="body2"
            gutterBottom
          >
          
          Chairman
          </Typography>

   </Box>
        
      </Grid>

  
    
  </Grid>
  </Card>
</Paper>

  
</Container>
   
  </div>
  <Paper sx={{py:2, padding:8}} elevation={5}>
  <Card sx={{ minWidth: 275, border: 0, boxShadow: 0  }}>
  <Grid container spacing={2}>
        <Grid
          // data-aos="fade-right"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="3000"
          sx={{textAlign:"left", color:"black"}}
          item xs={12} sm={12} md={6}>
          <Typography
            sx={{  fontSize: 22,fontWeight:600 }}
            
            gutterBottom
          >
       
          </Typography>
          
          <Typography
            sx={{ fontSize: 19 ,fontWeight:500,textAlign:"justify" }}
            variant="body2"
            gutterBottom
          >


{description}
          </Typography>


          <br />
      
        </Grid>

        <Grid
         
          sx={{textAlign:"left", color:"black"}}
          item xs={12} sm={12} md={6}>
            
           
            
            <Box sx={{marginLeft:15}} >
            <img height="220px" width="300px" src={image1} alt="" />
          <br/>
          <Typography
            sx={{ fontSize: 18,fontWeight:500,marginTop:4}}
            variant="body2"
            gutterBottom
          >
          
          {name}
          </Typography>
          <Typography
            sx={{ fontSize: 18,fontWeight:500}}
            variant="body2"
            gutterBottom
          >
          
         {desginaton}
          </Typography>
          
    </Box>

          </Grid>

      
        
      </Grid>

  </Card>

  </Paper>


  <Box sx={{marginTop:15}}>
  <>
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    // centeredSlides={true}
                    slidesPerView={3}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        loop: true,
                        speed: 600,

                        slideShadows: true
                    }
                    }
                    autoplay={{
                        delay: 1000
                    }}


                    pagination={true}
                    modules={[EffectCoverflow, Pagination, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src="https://i.ibb.co/1mfM1QH/a1.png" alt="..." width="450" height="350" />
                        <button  className="bg-black px-4 py-2 text-center" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/1mfM1QH/a1.png" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/Qvsh3zd/a2.png" alt="..." width="450" height="350" />
                        <button  className="bg-black px-4 py-2 text-center" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/Qvsh3zd/a2.png" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src="https://i.ibb.co/mqHyNbr/a3.png" alt="..." width="450" height="350" />
                        <button  className="bg-black px-4 py-2 text-center" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/mqHyNbr/a3.png" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/bH3Jt1G/a4.png" alt="..." width="350" height="350" />
                        <button  className="bg-black px-4 py-2 text-center" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/bH3Jt1G/a4.png" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="https://i.ibb.co/pWz1tVR/a5.png" alt="..." width="350" height="350" />
                        <button  className="bg-black px-4 py-2 text-center" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href="https://i.ibb.co/pWz1tVR/a5.png" target="_blank" rel="noopener noreferrer">View</a></button>
                    </SwiperSlide>
                    

                </Swiper>
            </>
  </Box>
     
      
     

      
    </Container>
      
      </div>
    );
};

export default AboutUs;