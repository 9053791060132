import {
    Box,
    Button,
    CardMedia,
    Container,
    Grid,
    Paper,
    Rating,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useContext, useEffect, useState } from "react";
  
  import { useParams } from "react-router-dom";
  import DateRangeIcon from "@mui/icons-material/DateRange";
  import StarIcon from "@mui/icons-material/Star";
  
  import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
  import PaidIcon from "@mui/icons-material/Paid";
  import {  Form,Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
 
 
  import BorderColorIcon from "@mui/icons-material/BorderColor";
  import PublishIcon from "@mui/icons-material/Publish";
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";
  
 
  
  
  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };
  const ProductDetails = () => {
   
    const [book, setBook] = useState({});
   
    const { id } = useParams();
  
    const [review, setReview] = useState([]);
    
    const [isFetched, setIsFetched] = useState(0);
    useEffect(() => {
      fetch(`https://turki-bd-server-ten.vercel.app/trades/${id}`)
        .then((res) => res.json())
        .then((data) => setBook(data));
    }, [id]);

  

    return (
      <>
        <Header></Header>
        <Container>
        
  
        <div className="">
         <Grid
          container
          spacing={2}
          sx={{ mt: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
         
         
       
         
         
        
            <Grid sx={{ py: 3 }} key={book._id} item xs={4} sm={4} md={4}>
              <Paper
                sx={{
                  p: 1,
                  margin: "auto",
                  maxWidth: 500,
                  flexGrow: 1,
                  boxShadow: "0px 14px 22px rgb(42 135 158 / 50%)"
                }}
              >
                <Grid  container spacing={2} columns={{ xs: 4, sm: 8, md: 4 }}>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className='photo'>
                    <div className='photoShops photoalbums'>
                      <img height="230" width="330" style={{borderRadius:"10px"}} src={book?.image2}></img>
                     
                   
                    </div>
                   </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={8} pl={2} my={3}>
                  <div className="d-flex justify-content-between pt-3" >
                       <button  className="bg-black px-2 py-2" style={{borderRadius:"10px"}}> <a className=' text-white' style={{textDecoration:"none",fontWeight:"600",}} href={book.image2} download target="_blank">Download</a></button>
                       <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href={book.image2} target="_blank" rel="noopener noreferrer">View</a></button>
                   </div>
                  </Grid>
                </Grid>
               
              </Paper>
            </Grid>
        
        </Grid>
                            </div>
        </Container>
  
        <Footer />
      </>
    );
  };
  
  export default ProductDetails;