import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Typewriter from 'typewriter-effect';
import Header from "../../Shared/Header/Header";
import Footer from "../../Shared/Footer/Footer";


const CertificateIata = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`https://turki-bd-server-ten.vercel.app/getcertificatediata`);
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  console.log(data)
const tittle1= data[0]?.tittle1
const tittle2= data[0]?.tittle2
const description1 = data[0]?.description1
const description2 = data[0]?.description2
const image1 = data[0]?.image1
  return (
    <>
    <section className="bg-black">
    <div>
        <Header/>

        <Container sx={{ my: 10}}>
        <div style={{marginTop:"50px"}}>
          <Grid container spacing={2}>
            <Grid
              // data-aos="fade-right"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-sine"
              // data-aos-duration="3000"
              sx={{ textAlign: "left", color: "white" }}
              item
              xs={12}
              sm={12}
              md={6}
            >
                  <h1 className="text-white fw-bold  display-2"   sx={{fontSize:"10px"}}>
                                       <Typewriter
                                     
                                           options={{
                                               strings: ["Turki Assoicate LTD"],
                                               autoStart: true,
                                               loop: true,
                                           }}
                                       />
                                   </h1>
              <Typography sx={{ fontSize: 22 }} gutterBottom>
                {tittle2}
              </Typography>

              <Typography sx={{ fontSize: 15 }} variant="body2" gutterBottom>
               {description1}
                
              </Typography>
              <Typography sx={{ fontSize: 15 }} variant="body2" gutterBottom>
               {description2}
                
              </Typography>

              
              
            </Grid>

            <Grid
              sx={{ textAlign: "left", color: "white" }}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <Box>
                <div>
                 <img height="400px" src="https://i.ibb.co/8PcX88z/trade.png"/>
                </div>
              </Box>
            </Grid> 
          </Grid>
        </div>
      </Container>

        <Footer/>
    </div>
    </section>
    </>
  );
};

export default CertificateIata;
