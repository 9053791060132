import React, { useEffect, useState } from 'react';

import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
const HomeRecruitment = () => {
  

      const [data, setData] = useState([]);
      const [loading, setLoading] = useState([]);
      useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`https://turki-bd-server-ten.vercel.app/getAssociate`);
            const result = await response.json();
            setData(result);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        }
        fetchData();
      }, []);

    

    return (
      <div>
    
        <Container sx={{ my: 10 }}>
      <Grid container spacing={2}>
        <Grid
          // data-aos="fade-right"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="3000"
          sx={{textAlign:"left", color:"white"}}
          item xs={12} sm={12} md={7}>
         
        
       


          <br />
          <img height="300px" width="550px" src="https://greenlandoverseasbd.com/public/frontEnd/img/illustrations/i6@2x.png" alt="" />
          <br/>
         
       
        </Grid>

        <Grid
         
          sx={{textAlign:"left", color:"black"}}
          item xs={12} sm={12} md={5}>
            
            
            
         
      
          
          
            <Box>
            <Typography className="icons" sx={{fontWeight:700}} variant="h5">
       
       
       </Typography>
            </Box>
       <Box> 
       <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"35px",marginTop:"70px"}} color="black" variant="h3">
       Recruitment Procedure Steps
        </Typography>
        <button style={{borderRadius:"10px",textAlign:"",marginTop:"10px"}} className='ms-3 bg-black text-white px-3 py-2'><Link style={{color:"white",textDecoration:"none"}}to="/recruitment">Details</Link></button>
        

       </Box>
            
          </Grid>

      
        
      </Grid>

      
    </Container>
       
      </div>
    );
};

export default HomeRecruitment;