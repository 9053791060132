import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion'; // Import motion from framer-motion
import { Card, CardContent, Grid, Typography, Container, Box, Paper } from '@mui/material';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';

const Circular = () => {
    const [menuTab, setMenuTab] = useState('All');
    const [loading, setLoading] = useState(false);
    const [collected, setCollected] = useState([]);

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        fetch('https://turki-bd-server-ten.vercel.app/getcircular')
            .then(res => res.json())
            .then(data => {
                setCollected(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setLoading(false);
            });
    }, []);

    const handleMenuTabs = (type) => {
        setMenuTab(type);
    }
const tittle=collected[0]?.tiittle
    return (
        <section className="bg-white">
            <Header/>
            <Container>
               
            <h2 className='text-black' style={{textAlign:"center",marginTop:"20px",ontSize:"2.8em", fontWeight:"700",marginTop:"100px", color: "black"}}>Circular</h2>
                <Grid sx={{marginTop:"10px"}} container spacing={4}>
               
                <div className="">
         <Grid
          container
          spacing={2}
          sx={{ mt: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
         {
         
       
         
         
         collected?.map((single) => (
            <Grid sx={{ py: 3 }} key={single._id} item xs={4} sm={4} md={4}>
              <Paper
                sx={{
                  p: 1,
                  margin: "auto",
                  maxWidth: 500,
                  flexGrow: 1,
                  boxShadow: "0px 14px 22px rgb(42 135 158 / 50%)"
                }}
              >
                <Grid  container spacing={2} columns={{ xs: 4, sm: 8, md: 4 }}>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className='photo'>
                    <div className='photoShops photoalbums'>
                      <img height="230" width="330" style={{borderRadius:"10px"}} src={single?.image1}></img>
                   
                    </div>
                   </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={8} pl={2} my={3}>
                  <div className="d-flex justify-content-between pt-3" >
                       <button  className="bg-black px-2 py-2" style={{borderRadius:"10px"}}> <a className=' text-white' style={{textDecoration:"none",fontWeight:"600",}} href={single.image1} download target="_blank">Download</a></button>
                       <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href={single.image1} target="_blank" rel="noopener noreferrer">View</a></button>
                   </div>
                  </Grid>
                </Grid>
               
              </Paper>
            </Grid>
          ))}
        </Grid>
                            </div>
                </Grid>
            </Container>
            <Footer/>
        </section>
    )
}

export default Circular;
