import React, { useEffect, useState } from "react";
import Header from "../Shared/Header/Header";
import Footer from "../Shared/Footer/Footer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Fade,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Ticket = () => {
  const [data, setData] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`https://turki-bd-server-ten.vercel.app/getAir`);
        const result = await response.json();
        setTicket(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`https://turki-bd-server-ten.vercel.app/getcertificatediata`);
          const result = await response.json();
          setData(result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
      fetchData();
    }, []);
  const description = ticket[0]?.description;
  const handleBooking = (e) => {
    fetch("https://turki-bd-server-ten.vercel.app/appointments", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      //   body:JSON.stringify(appointments)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedId) {
          // swal("Good job!", "Appointment has been Booked successfully !", "success");
          handleClose();
        }
      });

    alert("submit");

    e.preventDefault();
  };

  return (
    <>
      <section className="bg-white">
        <div>
          <Header />

          <Box
  sx={{
    width: '100%',
    height: '650px',
    backgroundImage: `url(https://i.ibb.co/n1HrDkm/tu1.jpg)`,
    backgroundSize: '100% 100%', // Change backgroundSize to cover the entire box
    backgroundRepeat: 'no-repeat',
    my: 3
  }}
>
  <Container sx={{ py: 15 }}>
    <Typography
      variant="h3"
      component="div"
      sx={{
        fontWeight: '700',
        color: 'black',
        textAlign: 'left',
        fontSize:"80px",
        marginTop:"150px",
        // marginRight:"-300px"
       
      }}
    >
      Mishal Aviation Services
      {/* Content here */}
    </Typography>
  </Container>
</Box>


          <div className="">
            <Container>

              {/* start certificated  */}

          <div className="">
         <Grid
          container
          spacing={2}
          sx={{ mt: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
         {
         
        
  
         
         
         data?.map((single) => (
            <Grid sx={{ py: 3 }} key={single._id} item xs={4} sm={4} md={4}>
              <Paper
                sx={{
                  p: 1,
                  margin: "auto",
                  maxWidth: 500,
                  flexGrow: 1,
                  boxShadow: "0px 14px 22px rgb(42 135 158 / 50%)"
                }}
              >
                <Grid  container spacing={2} columns={{ xs: 4, sm: 8, md: 4 }}>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className='photo'>
                    <div className='photoShops photoalbums'>
                      <img height="230" width="330" style={{borderRadius:"10px"}} src={single?.image1}></img>
                   
                    </div>
                   </div>
                  </Grid>
                  <Grid item xs={2} sm={4} md={8} pl={2} my={3}>
                  <div className="d-flex justify-content-between pt-3" >
                       <button  className="bg-black px-2 py-2" style={{borderRadius:"10px"}}> <a className=' text-white' style={{textDecoration:"none",fontWeight:"600",}} href={single.image1} download>Download</a></button>
                       <button  className="bg-black px-4 py-2" style={{borderRadius:"10px"}} ><a className='text-white' style={{textDecoration:"none",fontWeight:"600",}} href={single.image1} target="_blank" rel="noopener noreferrer">View</a></button>
                   </div>
                  </Grid>
                </Grid>
               
              </Paper>
            </Grid>
          ))}
        </Grid>
                            </div>
          {/* end certificated  */}
              <h2
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  textAlign: "left",
                  marginTop: "100px",
                  color: "black",
                }}
              >
                {description}{" "}
              </h2>
              <hr
                className=""
                style={{
                  width: "15%",
                  height: "5px",
                  backgroundColor: "black",
                  display: "inline-block",
                  border: 0,
                }}
              />
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
              >
                {/* 1st doctr  */}

                {ticket.map((datas) => (
                  <Grid item xs={2} sm={4} md={4}>
                    <Paper sx={{py:2}} elevation={5}>
                    <Card
                      sx={{
                        minWidth: 275,
                        minHeight: 350,
                        border: 0,
                        boxShadow: 0,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box className="p-2 ">
                          {/* <Typography className="icons" sx={{fontWeight:700,fontSize:"45px"}} variant="h5">

<i class="far fa-clock mt-5"></i>
</Typography> */}
                        </Box>
                        <Box sx={{ textAlign: "", padding: "8px" }}>
                          <Typography
                            className="dones ms-3"
                            sx={{
                              fontWeight: 700,
                              color: "black",
                              fontSize: "25px",
                              textAlign: "center",
                            }}
                            color="white"
                            variant="p"
                          >
                            {datas?.name}
                          </Typography>
                          <p
                            className="dones ms-3 p-2"
                            style={{ fontWeight: "500", textAlign: "left" }}
                            color="white"
                            variant="p"
                          >
                            {datas?.details}
                          </p>
                          <Box style={{ textAlign: "center" }}>
                            <button
                              onClick={handleOpen}
                              style={{ borderRadius: "10px", textAlign: "" }}
                              className="ms-3 bg-black text-white px-3 py-2"
                            >
                              <Link
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                                to="/ticketModal"
                              >
                                Details
                              </Link>
                            </button>
                          </Box>
                        </Box>
                      </Box>
                      <CardContent></CardContent>
                    </Card>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>

          <Footer />
        </div>
      </section>
    </>
  );
};

export default Ticket;
