import React, { useEffect, useState } from 'react';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
import './Gallery.css';
const Gallery = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState([]);
    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`https://turki-bd-server-ten.vercel.app/getGallery`);
          const result = await response.json();
          setData(result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
      fetchData();
    }, []);
   //  console.log(data)
 const tittle1=data[0]?.tittle1
 const tittle2=data[0]?.tittle2
 const tittle3=data[0]?.tittle3
 const tittle4=data[0]?.tittle4
 const tittle5=data[0]?.tittle5
 const tittle6=data[0]?.tittle6
 const tittle7=data[0]?.tittle7
 const details=data[0]?.details
 const image1=data[0]?.image1
 const image2=data[0]?.image2
 const image3=data[0]?.image3
 const image4=data[0]?.image4
 const image5=data[0]?.image5
 const image6=data[0]?.image6
 const image7=data[0]?.image7

 
    return (
        <div className='bg-white'>
<Header></Header>


            <div className="container mt-5">

            <h1 className='explores-design'> {tittle1}  <span className='turkidesigns'>{tittle2} <br></br>
</span> {tittle3}</h1>
  
            <div className="row">
                <div className="col-lg-6">

                <h2 className='upadate'>{tittle4} <span className='text'>{tittle5}</span></h2>

<h4 className='gallerytext'>{details}</h4>

                 

                </div>
                <div className="col-lg-6">

                    {/* start  */}

                    <div className="row">
                    <div className="col-lg-4">
                        <div className="row collections">
                        <img  className='collection mb-3 ' src={image1}/>
                        </div>
                        <div className="row collections">
                        <img  className='collection' src={image2}/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="row collectionss">
                        <img  className=' mb-3 collections' src={image3}/>
                        </div>
                        <div className="row collectionss">
                        <img  className=' mb-3 collections' src={image4}/>
                        </div>
                        <div className="row collectionss">
                        <img  className=' mb-3 collections' src={image5}/>
                        </div>
                       
                    </div>
                    <div className="col-lg-4">
                    <div className="row collections">
                        <img  className=' mb-3 collections' src={image6}/>
                        </div>
                        <div className="row collections">
                        <img  className=' mb-3 collections' src={image7}/>
                        </div>
                    </div>
                    

                </div>


                    {/* end  */}
                </div>
            </div>




            {/* start industry */}


          <div className='potter-update'>
          <div className="row">
                <div className="col-lg-6">

                <h2 className='upadate'>{tittle6} <span className='text'>{tittle7}</span></h2>


                    <h4 className='gallerytext'>{details}</h4>

                 

                </div>
                <div className="col-lg-6">

                    {/* start  */}
                    <div className="row">
                    <div className="col-lg-4">
                        <div className="row collections">
                        <img  className='collection mb-3 ' src={image1}/>
                        </div>
                        <div className="row collections">
                        <img  className='collection' src={image2}/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="row collectionss">
                        <img  className=' mb-3 collections' src={image3}/>
                        </div>
                        <div className="row collectionss">
                        <img  className=' mb-3 collections' src={image4}/>
                        </div>
                        <div className="row collectionss">
                        <img  className=' mb-3 collections' src={image5}/>
                        </div>
                       
                    </div>
                    <div className="col-lg-4">
                    <div className="row collections">
                        <img  className=' mb-3 collections' src={image6}/>
                        </div>
                        <div className="row collections">
                        <img  className=' mb-3 collections' src={image7}/>
                        </div>
                    </div>
                    

                </div>


                    {/* end  */}
                </div>
            </div> 
          </div>



            {/* end industrry  */}

            </div>
<Footer/>
        </div>
    );
};

export default Gallery;