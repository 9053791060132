import React, { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, Container, Fade, Grid, Modal, Paper, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Header from '../../Shared/Header/Header';
import Footer from '../../Shared/Footer/Footer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const TicketModalPart = () => {
    const [data, setData] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState([]);
    const newUserInfo={customerName:data.displayName}

    const [bookingInfo, setBookingInfo]=useState(newUserInfo)

    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`https://turki-bd-server-ten.vercel.app/getTicket`);
          const result = await response.json();
          setData(result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
      fetchData();
    }, []);
   
  const description=data[0]?.description

  const handleonBlur=(e)=>{
    const field=e.target.name;
    const value=e.target.value;
    const newInfo={...bookingInfo}
    newInfo[field]=value;
    console.log(newInfo)
    setBookingInfo(newInfo)
  }
    const handleBooking=e=>{
      const ticketBook={
        ...bookingInfo,
       
      }
       
        fetch('https://turki-bd-server-ten.vercel.app/postTicket', {
          method:'post',
          headers:{
            'content-type':'application/json'
          },
          body:JSON.stringify(ticketBook)
        })
        .then(res=>res.json())
        .then(data=> {
          if(data.insertedId){
            // swal("Good job!", "Appointment has been Booked successfully !", "success");
            handleClose()
          }
        })
          
  
        alert('submit')
         
          e.preventDefault()
      }


    return (
        <>
        <section className='bg-white'>
            <div>
                <Header/>

                <div className=''>

<Container>




<Box sx={{marginTop:"150px"}}>
<h2 style={{fontSize:"25px", fontWeight:"700", textAlign:"left", marginTop:"100px", color: "black"}}>{description} </h2>
<Box sx={{marginTop:"80px"}}>
<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
{/* 1st doctr  */}

{data.map((data) => (
     <Grid item xs={2} sm={4} md={4}>
     <Paper sx={{py:2}} elevation={5}>
     <Card sx={{ minWidth: 275, border: 0, boxShadow: 0  }}>
    
     <Box  sx={{display:"flex"}}>
    <Box className='p-2 '>
    {/* <Typography className="icons" sx={{fontWeight:700,fontSize:"45px"}} variant="h5">

<i class="far fa-clock mt-5"></i>
</Typography> */}
    </Box>
<Box sx={{textAlign:"left"}}>
<Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"25px"}} color="white" variant="p">
{data?.tittle}
</Typography>
<p className="dones ms-3 p-2" style={{fontWeight:"500"}}  color="white" variant="p">
{data?.details}
</p>
<button onClick={handleOpen} style={{borderRadius:"10px"}} className='ms-3 bg-black text-white px-3 py-2'>Booking</button>
<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
       
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
         
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography  className="mode" id="transition-modal-title" variant="h6" component="h2">
             Customer
            </Typography>


              <form onSubmit={handleBooking} className="mode">
              <TextField
             
              sx={{width:'90%',m:1}}
          id="outlined-size-small"
          defaultValue="Customer Name"
          onBlur={handleonBlur}
          name='CustomerName'
          size="small"
        />

            <TextField
             
              sx={{width:'90%',m:1}}
          id="outlined-size-small"
          defaultValue="Customer Email"
          onBlur={handleonBlur}
          name='CustomerEmail'
          size="small"
        />

            <TextField
             
              sx={{width:'90%',m:1}}
          id="outlined-size-small"
          defaultValue="Ticket Tittle"
          onBlur={handleonBlur}
          name='TicketTittle'
          size="small"
        />
            <TextField
             
              sx={{width:'90%',m:1}}
          id="outlined-size-small"
          defaultValue="Ticket quantity"
          onBlur={handleonBlur}
          name='quantity'
          size="small"
        />

           <TextField
             
              sx={{width:'90%',m:1}}
          id="outlined-size-small"
          defaultValue="Phone Number"
          onBlur={handleonBlur}
          name='phone'
          size="small"
        />
           <TextField
             
              sx={{width:'90%',m:1}}
          id="outlined-size-small"
          defaultValue="Ticket Detail"
          name='TicketDetail'
          onBlur={handleonBlur}
          size="small"
        />
        <Button onClick={handleOpen}  className="mode" type="submit" variant="contained">Booking</Button>
              </form>
          </Box>
        </Fade>
      </Modal>
</Box>
    </Box>
<CardContent>





</CardContent>

</Card>
</Paper>
</Grid>
 ))}


</Grid>
</Box>
</Box>
</Container>

            
        </div>

                <Footer/>
            </div>
        </section>
            
        </>
    );
};

export default TicketModalPart;