// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';





import About from './Pages/Homes/About/About';





import Contact from './Pages/Homes/Contact/Contact';
import Home from './Pages/Homes/Home/Home';
import Abouts from './Pages/Homes/About/About';
import Aboutsdata from './Pages/About/Abouts/Aboutsdata';
import Recruitment from './Pages/Recruitments/Recruitment';
import Team from './Pages/About/Team/Team';
import OurTeam from './Pages/About/Abouts/OurTeam/OurTeam';
import Trade from './Pages/Services/Trade/Trade';
import BlogMedia from './Pages/Blog/BlogMedia/BlogMedia';
import Ticket from './Pages/Ticketing/Ticket';
import CertificatedIata from './Pages/Services/Certificated_iata/CertificatedIata';
import CertificatedAtab from './Pages/Services/CertificatedAtab/CertificatedAtab';
import Gallery from './Pages/Gallery/Gallery';
import Tour from './Pages/Tour/Tour';
import Ban from './Pages/Homes/Banner/Ban';
import Training from './Pages/Training/Training';
import DemandLetter from './Pages/DemanndLetter/DemanndLetter';
import Country from './Pages/Country/Country';
import TicketModalPart from './Pages/Ticketing/TicketModalPart/TicketModalPart';
import Circular from './Pages/Circular/Circular';
import ProductDetails from './Pages/Country/CountryDetails/CountryDetails';





function App() {

  // const { admin, user } = useAuth()
  return (
    <div className="App">
   

        <BrowserRouter>
    <div>
    <Routes>
       <Route path="/" element={<Home />}/>
       <Route path="/ban" element={<Ban />}/>
       <Route path="/contact" element={<Contact />} />
       <Route path="/aboutus" element={<Aboutsdata />} />
       <Route path="/contact" element={<Contact />} />
       <Route path="/training" element={<Training />} />
       <Route path="/country" element={<Country />} />
       <Route path="/demanding" element={<DemandLetter />} />
       <Route path="/ticketModal" element={<TicketModalPart />} />
       <Route path="/recruitment" element={<Recruitment />} />
       <Route path="/ourteam" element={<OurTeam />} />
       <Route path="/trade" element={<Trade />} />
       <Route path="/Certificated_iata" element={<CertificatedIata/>} />
       <Route path="/atab_certificated" element={<CertificatedAtab/>} />
       <Route path="/gallery" element={<Gallery/>} />
       <Route path="/ticket" element={<Ticket />} />
       <Route path="/tour" element={<Tour />} />
       <Route path="/circular" element={<Circular />} />
       <Route path="bookDetails/:id" element={<ProductDetails />}/>
       <Route path="/demandLetter" element={<BlogMedia />} />

    
      
      
       
       
    
      
      

      


       </Routes>
    </div>
       </BrowserRouter>

       
     
    </div>
  );
}

export default App;
