import { Card, CardActions, CardContent, CardMedia, Grid, Paper, Typography,Container, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Header from '../Shared/Header/Header';
import Footer from '../Shared/Footer/Footer';
import TraningPart from './TraningPart';
// import './OurDoctor.css'
const Training = () => {

     const [data, setData] = useState([]);
      const [loading, setLoading] = useState([]);
      useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`https://turki-bd-server-ten.vercel.app/getTraining`);
            const result = await response.json();
            setData(result);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        }
        fetchData();
      }, []);
     //  console.log(data)
   const image2=data[0]?.image2
   const details2=data[0]?.details2
    return (
        <div className='bg-white'>
            <Header/>
            
            <Box
  sx={{
    width: '90%',
    height: '680px',
    textAlign:"center",
    marginLeft:"70px",
    backgroundImage: `url(https://i.ibb.co/BwSRVFF/traning.jpg)`,
    backgroundSize: '100% 100%', // Change backgroundSize to cover the entire box
    backgroundRepeat: 'no-repeat',
    my: 3
  }}
>
  <Container sx={{ py: 15 }}>
    <Typography
      variant="h3"
      component="div"
      sx={{
        fontWeight: '500',
        color: '#3B4757',
        textAlign: 'center'
      }}
    >
      {/* Content here */}
    </Typography>
  </Container>
</Box>


<Container>

  <TraningPart/>
 
<h2 style={{fontSize:"2.8em", fontWeight:"700",marginTop:"100px", color: "black"}}>Training</h2>
        <hr className='' style={{  width: '15%', height: '5px', backgroundColor: 'black', display:"inline-block", border:0}} />
<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
{/* 1st doctr  */}

{data.map((data) => (
     <Grid item xs={2} sm={4} md={4}>
     <Paper sx={{py:2}} elevation={5}>
     <Card sx={{ minWidth: 275, border: 0, boxShadow: 0  }}>
    
     <Box>
    <Box className='p-2 '>
    {/* <Typography className="icons" sx={{fontWeight:700,fontSize:"45px"}} variant="h5"> */}

<img style={{height:"250px", width:"350px"}} src={data?.image1}/>
{/* </Typography> */}
    </Box>
<Box sx={{textAlign:"left"}}>
<Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"25px"}} color="white" variant="p">
{data?.tittle}
</Typography>
<p className="dones ms-3 p-2" style={{fontWeight:"400"}}  color="white" variant="p">
{data?.details}
</p>
{/* <button  style={{borderRadius:"10px"}} className='ms-3 bg-black text-white px-3 py-2'>Read More</button> */}
</Box>
    </Box>
<CardContent>





</CardContent>

</Card>
</Paper>
</Grid>
 ))}
   <div>
    
    <Container sx={{ my: 10 }}>
  <Grid container spacing={2}>
    <Grid
      // data-aos="fade-right"
      // data-aos-offset="300"
      // data-aos-easing="ease-in-sine"
      // data-aos-duration="3000"
      sx={{textAlign:"left", color:"white"}}
      item xs={12} sm={12} md={6}>
     
    
   


      <br />
      <img height="450px" width="550px" src={image2} alt="" />
      <br/>
     
   
    </Grid>

    <Grid
     
      sx={{textAlign:"left", color:"white"}}
      item xs={12} sm={12} md={6}>
        
        
        
     
  
      
      
        <Box>
        <Typography className="icons" sx={{fontWeight:700}} variant="h5">
   
   
   </Typography>
        </Box>
   <Box>
   <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"20px",marginTop:"80px",textAlign:"justify"}} color="white" variant="h3">
  {details2}
    </Typography>
    {/* <button  style={{borderRadius:"10px"}} className='ms-3 bg-white text-black px-3 py-2 mt-3'>Read More</button> */}

   </Box>
        
      </Grid>

  
    
  </Grid>

  
</Container>
   
  </div>
    

</Grid>
</Container>

          <Footer/>  
        </div>
    );
};

export default Training;