import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import "./Header.css";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from "react-bootstrap/Dropdown";

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
      window.addEventListener("scroll", () => {
          if (window.scrollY > 50) {
              setSticky(true)
          } else {
              setSticky(false)
          }
      })
  }, []);
  return (
    <div className="header-area">
      <Navbar expand="lg"  >
        <Container>
          {/* onClick={handleClick} */}
          <img className="rounded" width='70' height='50' src='https://i.ibb.co/9nr5kH5/new-logo-turki.png'></img>
          <NavLink to="/wip" className="logo mt-2">
            {/* <span>Turki-bd </span> */}
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* onClick={handleClick} */}
            <Nav className="ms-auto menu d-flex align-items-center">
              {/* <Nav style={{color:'white'}}> */}
              <Nav.Link as={NavLink} to="/" className="menu-item">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/aboutus" className="menu-item">
                About
              </Nav.Link>
              <Dropdown>
                <Dropdown.Toggle className="drops" id="dropdown-basic">
                Recruitment
                </Dropdown.Toggle>

                <Dropdown.Menu className="drop-menu">
                  <Dropdown.Item className="down-color" href="/recruitment">
                  Recruitment
                  </Dropdown.Item>
                  <Dropdown.Item className="down-color" href="/demandLetter">
                    DemandLetter
                  </Dropdown.Item>
                  <Dropdown.Item className="down-color" href="/country">
                    Our Client
                  </Dropdown.Item>
                  <Dropdown.Item className="down-color" href="/circular">
                    Circular
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
             
              
              <Nav.Link as={NavLink} to="/training" className="menu-item">
              Training
              </Nav.Link>


           
            
              <Nav.Link as={NavLink} to="/ticket" className="menu-item">
                Ticket
              </Nav.Link>
              <Nav.Link as={NavLink} to="/gallery" className="menu-item">
                Gallery
              </Nav.Link>
              
              {/* <Dropdown>
      <Dropdown.Toggle className='drops' id="dropdown-basic">
       Blog
      </Dropdown.Toggle>

      <Dropdown.Menu className='drop-menu'>
     
        <Dropdown.Item className='down-color' href="/wip/blog">News & Media</Dropdown.Item>
        <Dropdown.Item className='down-color' href="/wip/certificated_iata">Features</Dropdown.Item>
        
       

      </Dropdown.Menu>
    </Dropdown> */}

    <Nav.Link as={NavLink} to="/contact" className="menu-item">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
