import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion'; // Import motion from framer-motion
import { Card, CardContent, Grid, Typography, Container, Box } from '@mui/material';
import './Tour.css'; // Import CSS file for styling

const Tour = () => {
    const [menuTab, setMenuTab] = useState('All');
    const [loading, setLoading] = useState(false);
    const [collected, setCollected] = useState([]);

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        fetch('https://turki-bd-server-ten.vercel.app/getTour')
            .then(res => res.json())
            .then(data => {
                setCollected(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                setLoading(false);
            });
    }, []);

    const handleMenuTabs = (type) => {
        setMenuTab(type);
    }
const tittle=collected[0]?.tiittle
    return (
        <section className="bg-white" style={{marginTop:"100px"}}>
            <Container>
                <h2 className='text-black' style={{ marginBottom:"60px",fontSize:"2.8em", fontWeight:"700",marginTop:"100px", color: "black"}}>{tittle}</h2>
                <div className="tab-container">
                    <p className={`tab ${menuTab === 'All' ? 'active' : ''}`} onClick={() => handleMenuTabs('All')}>AllService</p>
                    <p className={`ms-4 tab ${menuTab === 'Training' ? 'active' : ''}`} onClick={() => handleMenuTabs('Training')}>Training</p>
                    <p className={`ms-4 tab ${menuTab === 'AirLinces' ? 'active' : ''}`} onClick={() => handleMenuTabs('AirLinces')}>AirLinces</p>
                    <p className={`ms-4 tab ${menuTab === 'Ticket' ? 'active' : ''}`} onClick={() => handleMenuTabs('Ticket')}>Ticket</p>
                    <p className={`ms-4 tab ${menuTab === 'DemandLetter' ? 'active' : ''}`} onClick={() => handleMenuTabs('DemandLetter')}>DemandLetter</p>
                </div>
                <hr style={{background:"white", border:"2px solid white"}}></hr>
                <Grid sx={{marginTop:"30px"}} container spacing={4}>
                    {collected.filter(item => item.type === menuTab).map(item => (
                        <Grid item xs={12} sm={6} md={3} key={item.id}>
                            <AnimatePresence>
                            <motion.div
                                initial={{ x: '100vw' }} // Initial position outside the viewport
                                animate={{ x: 0 }} // Final position at 0 (left side)
                                exit={{ x: '-70vw' }} // Exit position outside the viewport from left side
                                transition={{ type: 'spring', stiffness: 25, duration: 60 }} // Spring animation with specified stiffness and duration
                                key="card" // Unique key for motion component
                            >
                                <Card className="tour-card">
                                    <img style={{height:"300px"}} src={item.image1} alt="Tour" className="card-image" />
                                  
                                </Card>
                            </motion.div>

                            </AnimatePresence>
                           
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </section>
    )
}

export default Tour;
