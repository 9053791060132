import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
const Assoicate = () => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

      const [data, setData] = useState([]);
      const [loading, setLoading] = useState([]);
      useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch(`https://turki-bd-server-ten.vercel.app/getAssociate`);
            const result = await response.json();
            setData(result);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        }
        fetchData();
      }, []);

      console.log(data)
     const tittle1=data[0]?.tittle1
     const tittle2=data[0]?.tittle2
     const tittle3=data[0]?.tittle3
     const tittle4=data[0]?.tittle4
     const tittle5=data[0]?.tittle5
     const details1=data[0]?.details1
     const details2=data[0]?.details2
     const details3=data[0]?.details3
     const description=data[0]?.description
     const designation=data[0]?.designation
     const name=data[0]?.name
     const image1=data[0]?.image1

    return (
      <div>
    
        <Container sx={{ my: 10 }}>
      <Grid container spacing={2}>
      {data?.length > 0 ? (
        <Grid
          // data-aos="fade-right"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="3000"
          sx={{textAlign:"left", color:"black"}}
          item xs={12} sm={12} md={6}>
        <Box>
            <Typography
            sx={{  fontSize: 28,fontWeight:600 }}
            
            gutterBottom
          >
         {tittle1}
          </Typography>
          <Typography
            sx={{ fontSize: 25,fontWeight:500 }}
            variant="h4"
            gutterBottom
            component="div"
          >
            {" "}
            <span style={{ color: "black" }}>{tittle2}</span>
          </Typography>
          <Typography
            sx={{ fontSize: 20,fontWeight:400 }}
            variant="body2"
            gutterBottom
          >


{description}
          </Typography>


          <br />
          <img height="200px" width="250px" src={image1} alt="" />
          <br/>
          <Typography
            sx={{ fontSize: 20,fontWeight:700 , marginTop:4}}
            variant="body2"
            gutterBottom
          >
          
         {name}
          </Typography>
          <Typography
            sx={{ fontSize: 20,fontWeight:700 }}
            variant="body2"
            gutterBottom
          >
          
         {designation}
          </Typography>
        </Box>
        </Grid>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '40vw', height: '50vh' }}>
        <Spinner animation="border" variant="primary" />
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px' }}></div>
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px', maxWidth: '330px' }}></div>
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px', maxWidth: '300px' }}></div>
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" style={{ height: '2px', maxWidth: '360px' }}></div>
        {/* <Spinner animation="border" /> */}
        <span className="sr-only">Loading...</span>
      </div>
          )}

     {data?.length > 0 ? (
        <Grid
         
          sx={{textAlign:"left", color:"black"}}
          item xs={12} sm={12} md={6}>
            
             <Box sx={{display:"flex"}}>
            <Box>
            <Typography className="icons" sx={{fontWeight:700,fontSize:30}} variant="h5">
       
       <i class="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box>
       <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"28px"}} color="white" variant="p">
       {tittle3}
        </Typography>
        <p className=" ms-3" style={{fontSize:"19px", fontWeight:"600"}} color="white" variant="p">
        {details1}
        </p>
       </Box>
            </Box>
            
             <Box sx={{display:"flex"}}>
            <Box>
            <Typography className="icons" sx={{fontWeight:700,fontSize:30}} variant="h5">
       
       <i class="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box>
       <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"28px"}} color="white" variant="p">
       {tittle4}
        </Typography>
        <p className="dones ms-3" style={{fontSize:"19px", fontWeight:"600"}} color="white" variant="p">
        {details2}
        </p>
       </Box>
            </Box>
             <Box sx={{display:"flex"}}>
            <Box>
            <Typography className="icons" sx={{fontWeight:700,fontSize:30}} variant="h5">
       
       <i class="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box>
       <Typography className="dones ms-3" sx={{fontWeight:700, color:"black", fontSize:"25px"}} color="white" variant="p">
      {tittle5}
        </Typography>
        <p className="dones ms-3" style={{fontSize:"19px", fontWeight:"600"}} color="white" variant="p">
       {details3}
        </p>
       </Box>
            </Box>
          </Grid>

) : (
  <div
    role="status"
    className="flex items-center mx-auto w-80 mt-10 justify-center h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
  >
    <svg
      className="w-10 h-10 text-gray-200 dark:text-gray-600"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 20"
    >
      <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
      <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
    </svg>
    <span className="sr-only">Loading...</span>
  </div>
)}

      
        
      </Grid>

      
    </Container>
       
      </div>
    );
};

export default Assoicate;