import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import connectImg from './images/contact.svg'

import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import SendIcon from '@mui/icons-material/Send';
import Header from '../../Shared/Header/Header';
import Footer from '../../Shared/Footer/Footer';






const Contact = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`https://turki-bd-server-ten.vercel.app/getContact`);
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  console.log(data)
 const tittle=data[0]?.tittle
 const subtittle1=data[0]?.subtittle1
 const description1=data[0]?.description1
 const subtittle2=data[0]?.subtittle2
 const description2=data[0]?.description2
 const subtittle3=data[0]?.subtittle3
 const description3=data[0]?.description3
 const subtittle4=data[0]?.subtittle4
 const description4=data[0]?.description4
 const subtittle5=data[0]?.subtittle5
 const description5=data[0]?.description5
 const locationTittle=data[0]?.locationTittle
 const address=data[0]?.address
 const mailTittle=data[0]?.mailTittle
 const mail=data[0]?.mail
 const callTittle=data[0]?.callTittle
 const number=data[0]?.number
 const openingTittle=data[0]?.openingTittle
 const openingStart=data[0]?.openingStart
 const openingClosed=data[0]?.openingClosed
  const {
    register,
    handleSubmit,
    reset} = useForm();
 

  const sendEmail = (formData) => {
    emailjs.send("service_es28khp", "template_1fmwbhh", formData, "user_bJFVwTvtJQe23vqEilEMy")

 .then(
  (result) => {

    result.text && alert('success', 'Success', 'Message sent successfully');
  },
  (error) => {

    error.text &&  alert('error', 'Error', 'Messege can not send');
  }
);
reset();  
  };

  const onSubmit = data => {


    fetch("https://blacks.onrender.com/contactus", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((result) => {
            if(result.insertedId){
                alert('added successfully');
                reset()
            }
            
        });
};

  return (

    <>
    {/* <Header></Header> */}
      {/* <NavigationBar /> */}
      {/* <SharedBanner pageName={"Contact Us"} /> */}
      <div style={{background:"white"}}>
        <Header></Header>

        <Container className='contacts' id='contacts'>



        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }} sx={{mt:3}}>
          <Grid item xs={4} sm={4} md={6} lg={6}>
            <Box style={{marginTop:"80px"}}>
          <Box sx={{textAlign:"left" }}>
          <Typography className='text-black' sx={{ mb: 2, fontWeight:"700", fontSize:"35px", }} variant='h4'>{tittle}</Typography>
                <Typography className='text-black' sx={{ mb: 4,fontWeight:"700", fontSize:"20px", }} variant='body'>{subtittle1}</Typography>
                <Typography className='text-black' sx={{ mb: 4,fontWeight:"400", fontSize:"20px", }} variant='h6'>{description1}</Typography>
          </Box>
            <Box  sx={{display:"flex"}}>
            <Box className='p-2 '>
            <Typography className="icons text-black" sx={{fontWeight:700,fontSize:"35px"}} variant="h5">
       
       <i className="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box sx={{textAlign:"left",marginTop:"20px"}}>
       <Typography className="dones ms-3 text-black" sx={{fontWeight:700, fontSize:"25px"}} color="white" variant="p">
       
       {subtittle2}
        </Typography>
        <p className="dones ms-3 p-2 text-black" style={{fontWeight:"500",fontSize:"20px"}}  variant="p">
       {description2}
        </p>
        
       </Box>
            </Box>

            <Box  sx={{display:"flex"}}>
            <Box className='p-2 '>
            <Typography className="icons text-black" sx={{fontWeight:700,fontSize:"35px"}} variant="h5">
       
       <i className="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box sx={{textAlign:"left",marginTop:"20px"}}>
       <Typography className="dones ms-3 text-black" sx={{fontWeight:700, fontSize:"25px"}} color="white" variant="p">
       
        {subtittle3}
        </Typography>
        <p className="dones ms-3 p-2 text-black" style={{fontWeight:"500",fontSize:"20px"}}  variant="p">
       {description3}
        </p>
        
       </Box>
            </Box>

            <Box  sx={{display:"flex"}}>
            <Box className='p-2 '>
            <Typography className="icons text-black" sx={{fontWeight:700,fontSize:"35px"}} variant="h5">
       
       <i className="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box sx={{textAlign:"left",marginTop:"20px"}}>
       <Typography className="dones ms-3 text-black" sx={{fontWeight:700, fontSize:"25px"}} color="white" variant="p">
       
       {subtittle4}
        </Typography>
        <p className="dones ms-3 p-2 text-black" style={{fontWeight:"500",fontSize:"20px"}}  variant="p">
        {description4}
        </p>
        
       </Box>
            </Box>

           

          

            <Box  sx={{display:"flex"}}>
            <Box className='p-2 '>
            <Typography className="icons text-black" sx={{fontWeight:700,fontSize:"35px"}} variant="h5">
       
       <i className="far fa-clock mt-5"></i>
       </Typography>
            </Box>
       <Box sx={{textAlign:"left",marginTop:"20px"}}>
       <Typography className="dones ms-3 text-black" sx={{fontWeight:700, fontSize:"25px"}} color="white" variant="p">
       
        {subtittle5}
        </Typography>
        <p className="dones ms-3 p-2 text-black" style={{fontWeight:"500",fontSize:"20px"}}  variant="p">
        {description5}
        </p>
        
       </Box>
            </Box>
            </Box>
          </Grid>

          <Grid item xs={4} sm={4} md={6} lg={6}>
            <Box sx={{ mb: 5 }}>
              <Box style={{marginTop:"80px"}}>
                <Typography className='text-black' sx={{ mb: 2, fontWeight:"700", fontSize:"35px" }} variant='h4'>Ask Your Question</Typography>
                <Typography className='text-black' sx={{ mb: 4,fontWeight:"700", fontSize:"20px"  }} variant='body'>Contact Us</Typography>

              </Box>


              <form  onSubmit={handleSubmit(onSubmit)}>

                <Box sx={{ mt: 3 }} style={{background:"white" , padding:"10px 20px"}} >

                  <TextField id="filled-basic" label="Name" fullWidth
                    variant="filled"{...register("name", { required: true })}
                    placeholder=" Your Name"
                    sx={{ my: 2 }} />
                  <TextField id="filled-basic" label="Email" fullWidth
                    variant="filled"{...register("email", { required: true })}
                    placeholder=" Your email"
                    sx={{ my: 2 }} />

                  <TextField id="filled-basic" label="Phone Number"
                    variant="filled" fullWidth {...register("number", { required: true })}
                    placeholder="Number"
                    sx={{ my: 2 }} />



                  <TextField fullWidth
                    id="outlined-multiline-static"
                    variant="filled"
                    label="Massage"
                    multiline
                    rows={4}

                    {...register("message", { required: true })} placeholder=" Your Massage"
                    sx={{ my: 2 }} /> <br />


                  <Button type="submit" fullWidth variant="contained"  endIcon={<SendIcon />}> Send</Button>


                </Box>


              </form>

            </Box>
          </Grid>
        </Grid>

        <Box sx={{ my: 5 }}>
          <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
            <Grid item xs={2} sm={4} md={3} lg={3}>
              <Box>
                <Box sx={{ ml: 5 }}>
                  {/* <img src="https://i.ibb.co/RjMJ1NV/location-removebg-preview-1.png" alt="" /> */}
                </Box>
                <Box>
                  <Typography sx={{ ml: 4 }} variant='h5' style={{color:"black",fontWeight:"600",fontSize:"30px"}}>{locationTittle}</Typography>
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"18px"}}>{address}</Typography><br />
                </Box>
              </Box>

            </Grid>
            <Grid item xs={2} sm={4} md={3} lg={3}>
              <Box>
                <Box sx={{ ml: 5 }}>
                  {/* <img src="https://i.ibb.co/kgC58zp/email-removebg-preview-1.png" alt="" /> */}
                </Box>
                <Box>
                  <Typography sx={{ ml: 5 }} variant='h5' style={{color:"black",fontWeight:"600",fontSize:"30px"}}>{mailTittle}</Typography>
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"20px"}}>{mail}</Typography><br />
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"20px"}}>{mail}</Typography>

                </Box>

              </Box>

            </Grid>
            <Grid item xs={2} sm={4} md={3} lg={3}>
              <Box>
                <Box sx={{ ml: 4 }}>
                  {/* <img src="https://i.ibb.co/J2DjHCn/mobilee-removebg-preview-1.png" alt="" /> */}
                </Box>
                <Box>
                  <Typography sx={{ ml: 4 }} variant='h5' style={{color:"black",fontWeight:"600",fontSize:"30px"}}>{callTittle}</Typography>
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"20px"}}>{number}</Typography><br />
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"20px"}}>{number}</Typography>
                </Box>


              </Box>

            </Grid>
            <Grid item xs={2} sm={4} md={3} lg={3}>
              <Box>
                <Box sx={{ ml: 5 }}>
                  {/* <img src='https://i.ibb.co/YRmJtDr/clock-1.png' alt="" /> */}
                </Box>
                <Box>
                  <Typography sx={{ ml: 2 }} variant='h5' style={{color:"black",fontWeight:"600",fontSize:"30px"}}>{openingTittle}</Typography>
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"18px"}}>{openingStart}</Typography><br />
                  <Typography variant='body' style={{color:"black",fontWeight:"500",fontSize:"18px"}}>{openingClosed}</Typography>
                </Box>


              </Box>

            </Grid>
          </Grid>
        </Box>

      </Container>

        <Footer></Footer>
      </div>
      {/* <Footer></Footer> */}
      {/* <Footer /> */}
    </>






  );
}
  ;

export default Contact;