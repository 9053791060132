import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Footer.css';
import { FaArrowRight, FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaLinkedin, FaPinterest } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        if (email) {
            alert("Thanks for Subscribing our newsletter")
        }
    }
    return (
    <section style={{background:"black"}}>
         <footer className='footer-area pt-5 pb-3 mt-5'>
            <div className="footer-top">
                <Container>
                    <Row>
                        <Col md={3}>
                            <div className="footer-box">
                                <h4 style={{color:"white"}}>Get In Touch</h4>
                                <div className="links mt-4">
                                    <ul>
                                        <Link style={{textDecoration:"none"}} to={'/contact'}>
                                            <li> <FaArrowRight className='link-icon' />The Turki Associate Ltd is one of the government authorized recruiting office and a well established farm all over the Asian countries with qualified and experienced personnel to provide all types of professional and technical assistance for the recruitment</li>
                                        </Link>
                                        <div className='contact-road'>
                               <i class="fas fa-map-marker-alt"></i>
                              
                               <div className='ms-2' style={{color:"white"}}>
                               Nasir Trade Center (5th Floor) 89 Bir Uttam C.R Datta Road Dhaka
                               </div>
                               </div>
                               <div className='contact-road'>
                               <i class="fas fa-phone-square-alt"></i>
                              
                               <div className='ms-2' style={{color:"white"}}>
                               +8802223360122
                               </div>
                               </div>
                               <div className='contact-road' style={{color:"white"}}>
                               <i className="fas fa-envelope"></i>
                              
                               <div className='ms-2' style={{color:"white"}}>
                               turki424@gmail.com
                               </div>
                               </div>
                               <div className='contact-road' style={{color:"white"}}>
                               <i class="fas fa-comment-dots"></i>
                              
                               <div className='ms-2'>
                               https://www.turkibd.com/
                               </div>
                               </div>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="footer-box">
                                <h4 style={{color:"white"}}>Useful Category</h4>
                                <div className="links mt-4">
                                    <ul>
                                    <NavLink style={{textDecoration:"none"}} to={'/wip'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Home</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/country'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Country</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/ourteam'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Team</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/demanding'} activeClassName="active"><li> <FaArrowRight className='link-icon' />DemandLetter</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/gallery'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Gallery</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/ticket'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Ticket</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/recruitment'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Recruitment</li></NavLink>
    <NavLink style={{textDecoration:"none"}} to={'/wip/blog'} activeClassName="active"><li> <FaArrowRight className='link-icon' />Blog</li></NavLink>
                                        <NavLink style={{textDecoration:"none"}} to={'/wip/training'}><li> <FaArrowRight className='link-icon' />Training</li></NavLink>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="footer-box">
                                <h4 style={{color:"white"}}>Recent News</h4>
                              
                                <div className=" mt-4">
                                <div className='row me-5'>
                                    <div className=" col-lg-3">
                                        <img height="50px" src="https://i.ibb.co/4pKdH7k/to4.jpg"/>
                                    </div>
                                    <div className="col-lg-9">
                                    <p style={{color:"white", marginLeft:"5px",textAlign:"left"}}>The Turki Associate Ltd providing technically competent and highly motivated workers only.</p>
                                    </div>
                                   
                                </div>
                                <div className='row me-5'>
                                    <div className=" col-lg-3">
                                        <img height="50px" src="https://i.ibb.co/4pKdH7k/to4.jpg"/>
                                    </div>
                                    <div className="col-lg-9">
                                    <p style={{textAlign:"left",color:"white",marginLeft:"5px"}}>The Turki Associate Ltd providing technically competent and highly motivated workers only.</p>
                                    </div>
                                   
                                </div>
                                   
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="footer-box">
                                <h4 style={{color:"white"}}>Subscribe Newsletter</h4>
                                <div className="subscribe-form mt-4">
                                    <form onSubmit={handleSubmit}>
                                        <input onChange={(e) => setEmail(e.target.value)} type="email" required placeholder='Enter Email' />
                                        <button type='submit'>Subscribe <FaArrowRight className='link-icon' /></button>
                                    </form>
                                </div>
                                <div className="footer-social mt-3">
                                    <h5 style={{color:"white"}}>Share Social Media</h5>
                                    <FaFacebook style={{color:"white"}} className='social-icon' />
                                    <FaInstagram style={{color:"white"}} className='social-icon' />
                                    <FaTwitter style={{color:"white"}} className='social-icon' />
                                    <FaYoutube style={{color:"white"}} className='social-icon' />
                                    <FaTwitter style={{color:"white"}} className='social-icon' />
                                    <FaLinkedin style={{color:"white"}} className='social-icon' />
                                    <FaPinterest style={{color:"white"}} className='social-icon' />
                                </div>
                                <div className='mt-2'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14608.117138091406!2d90.392441!3d23.7463351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8bdbff77785%3A0x31c76727f2a562b5!2sNasir%20Trade%20Center!5e0!3m2!1sen!2sbd!4v1702978550103!5m2!1sen!2sbd"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr style={{color:"white"}} className='horizontal-line' />
            <div className="footer-bottom">
                <Container>
                    <Row>
                        <Col>
                            <p className="copyright-text text-center mt-2" style={{color:"white"}}>
                            Design and developed By Face of Art Technologies
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
       </section>
    );
};

export default Footer;