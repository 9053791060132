import React, { useEffect, useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';

import "./Banners.css"
import { Link } from 'react-router-dom';
import { Card, CardActions, CardContent, CardMedia,  Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typewriter from 'typewriter-effect';
const Banners = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState([]);
    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch(`https://turki-bd-server-ten.vercel.app/getbanner`);
          const result = await response.json();
          setData(result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
      fetchData();
    }, []);
// console.log(data[0])
const tittle1=data[0]?.tittle1
const tittle2=data[0]?.tittle2
const tittle3=data[0]?.tittle3
const bottomtittle1=data[0]?.bottomtittle1
const bottomtittle2=data[0]?.bottomtittle2
const bottomtittle3=data[0]?.bottomtittle3


    return (

     
      <>
               

        <section>
            <div>

<Carousel className="banners hide-indicators" >
  <Carousel.Item className=" valuesPicture" indicators={false}>
  <section className="hero-section">
           
           <div className="container">
               <div className="row pt-2 pb-5">
                   <div className="d-flex justify-content-center align-items-center ">
                       <div>
                           <div id="home" className="row align-items-center d-flex  justify-content-start px-5 my-5 py-5">
                               <div className=" text-center my-5 py-5 ">
                                   <h1 className="text-white fw-bold  display-2">
                                       <Typewriter
                                           options={{
                                               strings: ["Turki Assoicate LTD"],
                                               autoStart: true,
                                               loop: true,
                                           }}
                                       />
                                   </h1>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>
           </div>

       </section>
  
  
    

    <Container>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>



      
      <Grid item xs={4} sm={4} md={4}>
<Paper className="keys dell" sx={{}} elevation={5}>
             <Card className="keys dell" sx={{  border: 0, boxShadow: 0  }}>
            
             
      <CardContent className="dell"  sx={{}}>
          
        <Box className="mouses">
        <Typography className="icons" sx={{fontWeight:700}} variant="h5">
        {/* <i class="fas fa-map-marker-alt"></i> */}
        <i class="far fa-clock ms-3"></i>
        </Typography>
        <Typography className="dones" sx={{fontWeight:700, color:"white"}} color="white" variant="p">
        {bottomtittle1}
        </Typography>

        </Box>
      
       
        
      </CardContent>
     
    </Card>
    </Paper>
       
      </Grid>

{/* 2nd step  */}
<Grid item xs={4} sm={4} md={4}>
<Paper className="keys dell" sx={{}} elevation={5}>
             <Card className="keys dells" sx={{  border: 0, boxShadow: 0  }}>
            
             
      <CardContent className="dells"  sx={{}}>
          
        <Box className="mouses">
        <Typography className="icons" sx={{fontWeight:700}} variant="h5">
        <i class="fas fa-map-marker-alt ms-5"></i>
        </Typography>
        <Typography className="dones" sx={{fontWeight:700, color:"white"}} color="white" variant="p">
        {bottomtittle2}
        </Typography>

        </Box>
      
       
        
      </CardContent>
     
    </Card>
    </Paper>
       
      </Grid>


      {/* 3rd step  */}

      <Grid item xs={4} sm={4} md={4}>
<Paper className="keys" sx={{}} elevation={5}>
             <Card className="keys" sx={{ border: 0, boxShadow: 0  }}>
            
             
      <CardContent className="dell"  sx={{}}>
          
        <Box className="mouses">
        <Typography sx={{fontWeight:700}} variant="h5">
        <i class="fas fa-phone-alt "></i>
        </Typography>
        <Typography sx={{fontWeight:700}} color="white" variant="p">
        {bottomtittle3}
        </Typography>

        </Box>
      
       
        
      </CardContent>
     
    </Card>
    </Paper>
       
      </Grid>

</Grid>
</Container>
  

   
  </Carousel.Item>
  
  {/* 2dn the item  */}
  <Carousel.Item className="carousel-design valuesPicture">
  <section className="hero-section2">
           
           <div className="container">
               <div className="row pt-2 pb-5">
                   <div className="d-flex justify-content-center align-items-center ">
                       <div>
                           <div id="home" className="row align-items-center d-flex  justify-content-start px-5 my-5 py-5">
                               <div className=" text-center my-5 py-5 ">
                                   <h1 className="text-white fw-bold  display-2">
                                       <Typewriter
                                           options={{
                                               strings: ["Turki Assoicate LTD"],
                                               autoStart: true,
                                               loop: true,
                                           }}
                                       />
                                   </h1>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>
           </div>

       </section>

  
    <Container>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>



      
<Grid item xs={4} sm={4} md={4}>
<Paper className="keys dell" sx={{}} elevation={5}>
       <Card className="keys dell" sx={{  border: 0, boxShadow: 0  }}>
      
       
<CardContent className="dell"  sx={{}}>
    
  <Box className="mouses">
  <Typography className="icons" sx={{fontWeight:700}} variant="h5">
  {/* <i class="fas fa-map-marker-alt"></i> */}
  <i class="far fa-clock ms-3"></i>
  </Typography>
  <Typography className="dones" sx={{fontWeight:700, color:"white"}} color="white" variant="p">
  {bottomtittle1}
  </Typography>

  </Box>

 
  
</CardContent>

</Card>
</Paper>
 
</Grid>

{/* 2nd step  */}
<Grid item xs={4} sm={4} md={4}>
<Paper className="keys dell" sx={{}} elevation={5}>
       <Card className="keys dells" sx={{  border: 0, boxShadow: 0  }}>
      
       
<CardContent className="dells"  sx={{}}>
    
  <Box className="mouses">
  <Typography className="icons" sx={{fontWeight:700}} variant="h5">
  <i class="fas fa-map-marker-alt ms-5"></i>
  </Typography>
  <Typography className="dones" sx={{fontWeight:700, color:"white"}} color="white" variant="p">
  {bottomtittle2}
  </Typography>

  </Box>

 
  
</CardContent>

</Card>
</Paper>
 
</Grid>


{/* 3rd step  */}

<Grid item xs={4} sm={4} md={4}>
<Paper className="keys" sx={{}} elevation={5}>
       <Card className="keys" sx={{ border: 0, boxShadow: 0  }}>
      
       
<CardContent className="dell"  sx={{}}>
    
  <Box className="mouses">
  <Typography sx={{fontWeight:700}} variant="h5">
  <i class="fas fa-phone-alt"></i>
  </Typography>
  <Typography sx={{fontWeight:700}} color="white" variant="p">
  {bottomtittle3}
  </Typography>

  </Box>

 
  
</CardContent>

</Card>
</Paper>
 
</Grid>

</Grid>
</Container>
  </Carousel.Item>
  <Carousel.Item className="carousel-design valuesPicture">
  <section className="hero-section3">
           
           <div className="container">
               <div className="row pt-2 pb-5">
                   <div className="d-flex justify-content-center align-items-center ">
                       <div>
                           <div id="home" className="row align-items-center d-flex  justify-content-start px-5 my-5 py-5">
                               <div className=" text-center my-5 py-5 ">
                                   <h1 className="text-white fw-bold  display-2">
                                       <Typewriter
                                           options={{
                                               strings: ["Turki Assoicate LTD"],
                                               autoStart: true,
                                               loop: true,
                                           }}
                                       />
                                   </h1>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>
           </div>

       </section>
    
   
    <Container>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>



      
<Grid item xs={4} sm={4} md={4}>
<Paper className="keys dell" sx={{}} elevation={5}>
       <Card className="keys dell" sx={{  border: 0, boxShadow: 0  }}>
      
       
<CardContent className="dell"  sx={{}}>
    
  <Box className="mouses">
  <Typography className="icons" sx={{fontWeight:700}} variant="h5">
  {/* <i class="fas fa-map-marker-alt"></i> */}
  <i class="far fa-clock ms-3"></i>
  </Typography>
  <Typography className="dones" sx={{fontWeight:700, color:"white"}} color="white" variant="p">
 {bottomtittle1}
  </Typography>

  </Box>

 
  
</CardContent>

</Card>
</Paper>
 
</Grid>

{/* 2nd step  */}
<Grid item xs={4} sm={4} md={4}>
<Paper className="keys dell" sx={{}} elevation={5}>
       <Card className="keys dells" sx={{  border: 0, boxShadow: 0  }}>
      
       
<CardContent className="dells"  sx={{}}>
    
  <Box className="mouses">
  <Typography className="icons" sx={{fontWeight:700}} variant="h5">
  <i class="fas fa-map-marker-alt ms-5"></i>
  </Typography>
  <Typography className="dones" sx={{fontWeight:700, color:"white"}} color="white" variant="p">
  {bottomtittle2}
  </Typography>

  </Box>

 
  
</CardContent>

</Card>
</Paper>
 
</Grid>


{/* 3rd step  */}

<Grid item xs={4} sm={4} md={4}>
<Paper className="keys" sx={{}} elevation={5}>
       <Card className="keys" sx={{ border: 0, boxShadow: 0  }}>
      
       
<CardContent className="dell"  sx={{}}>
    
  <Box className="mouses">
  <Typography sx={{fontWeight:700}} variant="h5">
  <i class="fas fa-phone-alt"></i>
  </Typography>
  <Typography sx={{fontWeight:700}} color="white" variant="p">
  {bottomtittle3}
  </Typography>

  </Box>

 
  
</CardContent>

</Card>
</Paper>
 
</Grid>

</Grid>
</Container>
  </Carousel.Item>
  
</Carousel>
            
        </div>
        </section>


    
      </>
    );
};

export default Banners;